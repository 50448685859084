import Events from "./Components/Events/Events";
import Header from "./Components/Header/Header";
import { RSVPForm } from "./Components/index"; //Move all these imports into this index?
import Stay from "./Components/Stay/Stay";
import Splash from "./Components/Splash/Splash";
import Info from "./Components/Info/Info";

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <main>
          <Splash />
          <Events />
          <Stay />
          <Info />
          <RSVPForm />
        </main>
      </div>
    </>
  );
}

export default App;
