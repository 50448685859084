import { useState, useEffect } from "react";

const HeroImage = ({ type }: { type: string }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  let altText = '';
  switch (type) {
    case 'invite':
      altText = 'Invitation information';
      break;
    case 'park':
      altText = 'Skinnarvik Park';
      break;
    case 'skyddsrummet':
      altText = 'The venue skyddsrummet';
      break;
    case 'bridge':
      altText = 'View of bridge at Långholmen';
      break;    
  }

  const prefix = windowWidth < 770 && type === 'invite' ? 'mobile-' : '';
  const url = 'assets/images/' + prefix + type + '.jpg';

  return (
    <div className='imgContainer'>
      <img src={url} alt={altText}></img>
    </div>
  );
};

export default HeroImage;