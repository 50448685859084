import HeroImage from "../HeroImage/HeroImage";

const Events = () => {
    return (
      <div className="events-section">
        <div className="section-title-box-mobile" id="section-title-box-events">
          <div className='iconContainer'>
            <img src="assets/images/events.svg" alt="Events section header"></img>
          </div>
        </div>
        <a className="anchor" id="anchor-events"></a>
        <HeroImage type={"park"} />
        <div className="event-container">
          <div className="section-title-box-desktop">
            <div className='iconContainer'>
              <img src="assets/images/events.svg" alt="Events section header"></img>
            </div>
          </div>
          <div className="events-date-wrapper">
            <div className="events-date">
              10/8<br /> 
              Thursday
            </div>
          </div>
          <div className="events-content-wrapper">
            <h1>EVENING BEFORE</h1>
            <div className="event-info-line">
              <p className="event-info-left">Time</p><p className="event-info-right">20:00</p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Location</p><p className="event-info-right">
                Skinnarviksparken<br />
                Skinnarbacken<br />
                11727 Stockholm
              </p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Details</p><p className="event-info-right">Completely optional hangout the day before the party. A nice opportunity to catch up with old friends and meet new ones. Bring your own drinks and snacks and come meet us while we try and catch the sunset!<br /><br />UPDATE: The forecast isn’t looking great. Please shoot Pej a text (+46790341242) so we can figure out how many people planned on coming. A warmer plan B is probably needed, maybe we can hit a pub or something!</p>
            </div>
          </div>
        </div>
        <HeroImage type={"skyddsrummet"} />
        <div className="event-container">
        <div className="section-title-box-desktop"></div>
          <div className="events-date-wrapper">
            <div className="events-date">
              11/8<br /> 
              Friday
            </div>
          </div>
          <div className="events-content-wrapper">
            <h1>WEDDING DAY</h1>
            <div className="event-info-line">
              <p className="event-info-left">Time</p><p className="event-info-right">16:00</p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Location</p><p className="event-info-right">
                Skyddsrummet<br />
                Söder Mälarstrand 25B<br />
                11825 Stockholm
              </p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Details</p><p className="event-info-right">The main event! We’ll start with a ceremony followed by drinks and chats before transitioning into dinner. After dinner it’s time to paaaartay!</p>
            </div>
          </div>
        </div>
        <HeroImage type={"bridge"} />
        <div className="event-container">
        <div className="section-title-box-desktop"></div>
          <div className="events-date-wrapper">
            <div className="events-date">
              12/8<br /> 
              Saturday
            </div>
          </div>
          <div className="events-content-wrapper">
            <h1>HANGOVER</h1>
            <div className="event-info-line">
              <p className="event-info-left">Time</p><p className="event-info-right">9:00</p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Location</p><p className="event-info-right">
                Långholmen Hotell<br />
                Långholmsmuren 20<br />
                11733 Stockholm
              </p>
            </div>
            <div className="event-info-line">
              <p className="event-info-left">Details</p><p className="event-info-right">We’ll be having breakfast at Långholmen Hotell and we will stay at the park for a while before crawling back into bed. For anyone who wants to hang-over a little before going their own way.</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Events;
