const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-links">
        <a href="#anchor-events">Events</a>
        <a href="#anchor-stay">Stay</a>
        <a href="#anchor-info">Info</a>
        <a href="#anchor-rsvp">Rsvp</a>
      </div>
    </nav>
  );
};

export default Navbar;
