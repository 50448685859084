import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io/index';

const StayWithUs = () => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="stay-with-us-wrapper">
      <div className="section-title-box-mobile">
        <div className="iconContainer">
          <img
            src="assets/images/stay.svg"
            alt="Stay section header"
          ></img>
        </div>
      </div>
      <div className="section-title-box-desktop">
        <div className="iconContainer">
          <img
            src="assets/images/stay.svg"
            alt="Stay section header"
          ></img>
        </div>
      </div>
      <div className="stay-with-us">
        <a
          className="anchor"
          id="anchor-stay"
        ></a>
        <h1 id="anchor-stay">Långholmen Hotell</h1>
        <p>
          In the heart of the city but close to nature lies the island
          Långholmen. It holds an old prison turned into a unique hotel. A 20
          minute scenic walk by the water or a 5 minute Uber drive from the
          venue.
        </p>
        <p>
          This is the hotel we will be staying in and we would love for you to
          stay there as well. We have a discount code for those who do.
        </p>
        <button
          className="how-to-book-btn"
          onClick={() => setHidden(!hidden)}
        >
          <div>
            <p className="slide-down-label">Prices & How to book</p>
            <IoIosArrowDown
              className={hidden ? 'arrow-down-closed' : 'arrow-down-open'}
            />
          </div>
        </button>
        <div className={hidden ? 'slide-up' : 'slide-down'}>
          <div className="slide-down-content">
            <p className="slide-down-content-subheading">
              Room prices (breakfast included)
            </p>
            <p>Compact Double Cell 1600 SEK/night</p>
            <p>Double Cell 1900 SEK/night</p>
            <p>Family Cell 2200 SEK/night</p>
            <p>Single Cell 900 SEK/night</p>
            <br />
            <p>
              Check out the rooms{' '}
              <a
                href="https://langholmen.com/en/hotel/hotel-rooms/"
                target="_blank"
              >
                here
              </a>
            </p>
            <br />
            <p className="slide-down-content-subheading">How to book</p>
            <p>
              We have booked rooms in advance. Simply send an email to{' '}
              <a href="mailto:grupp@langholmen.com">grupp@langholmen.com</a>{' '}
              with the event number (6716) to make a reservation.
            </p>
            <br />
            <p className="slide-down-content-italicised">
              "Hi, I would like to reserve a booked [room of choice] from Event
              6716 from [arrival date] to [departure date]."
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayWithUs;
