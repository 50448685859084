import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io/index";

const Info = () => {
  const [hiddenGifts, setHiddenGifts] = useState(true);
  const [hiddenToasts, setHiddenToasts] = useState(true);
  const [hiddenWishes, setHiddenWishes] = useState(true);
  const [hiddenTips, setHiddenTips] = useState(true);

  return (
    <div className="info-wrapper">
      <a className="anchor" id="anchor-info"></a>
      <div className="section-title-box-mobile">
      <div className='iconContainer'>
        <img src="assets/images/info.svg" alt="Info section header"></img>
      </div>
      </div>
      <div className="section-title-box-desktop">
        <div className='iconContainer'>
          <img src="assets/images/info.svg" alt="Info section header"></img>
        </div>
      </div>
      <div className="info">
        <h1>Good to Know</h1>
        <p>
          Here is all the info you need regarding the day, and some tips if you’re traveling.
        </p>

        <button className="expandable-btn" onClick={() => setHiddenGifts(!hiddenGifts)}>
          <div>
            <p className="slide-down-label">Wedding Gifts</p>
            <IoIosArrowDown
              className={hiddenGifts ? "arrow-down-closed" : "arrow-down-open"}
            />
          </div>
        </button>
        <div className={hiddenGifts ? "slide-up" : "slide-down"}>
          <div className="slide-down-content">
            <p>
              Your presence is a gift! We know that many of you are travelling from afar. Having loving family and friends celebrate this day with is everything we need.
            </p>
            <br />
            <p>If you’d like to send a card, our address is:</p> 
            <p>Petra & Oskar Wettergren - van Urk</p>
            <p>Lundagatan 44D</p>
            <p>11727 Stockholm</p>
            <br />
          </div>
        </div>

        <button className="expandable-btn" onClick={() => setHiddenToasts(!hiddenToasts)}>
          <div>
            <p className="slide-down-label">Toastmaster & Speeches</p>
            <IoIosArrowDown
              className={hiddenToasts ? "arrow-down-closed" : "arrow-down-open"}
            />
          </div>
        </button>
        <div className={hiddenToasts ? "slide-up" : "slide-down"}>
          <div className="slide-down-content">
            <p>
              It’s custom in Urker weddings to have a “stukkien” (the Urkers can explain that one) — but since this is a Swedish event we’ll be going with Swedish tradition and we’ll have Speeches during dinner. If you would like to give a speech, please reach out to our toastmaster Jonas at <a href="mailto:proostmeester.jonas@gmail.com">proostmeester.jonas@gmail.com</a>.
            </p>
            <br />
            <p>
            To the people who have no idea what a toastmaster is: A toastmaster is the person who coordinates toasts, introduces speakers and other formal announcements.</p> 
            <br />
          </div>
        </div>

        <button className="expandable-btn" onClick={() => setHiddenWishes(!hiddenWishes)}>
          <div>
            <p className="slide-down-label">Wishes from Us</p>
            <IoIosArrowDown
              className={hiddenWishes ? "arrow-down-closed" : "arrow-down-open"}
            />
          </div>
        </button>
        <div className={hiddenWishes ? "slide-up" : "slide-down"}>
          <div className="slide-down-content">
            <p>
              Please no phones during the ceremony. There will be plenty moments during the day where everyone can snap some pictures of their own!
            </p>
            <br />
            <p>
              We love kids but this day is for adults — Except for Pej’s nieces and nephews.
            </p>
            <br />
            <p>
              We don’t have a dress code so wear something that you think is appropriate for a wedding. It’s (hopefully) going to be a nice summer day!
            </p>
            <br />
            <p>
              Unfortunately we have limited spaces, so please no plus ones unless explicitly discussed beforehand.
            </p>
            <br />
            <p>
              No expectations from us otherwise, have fun and party the way you party best. The venue closes at 02:00 but if you’re tired and want to go home you can totally do an Irish exit.️ We probably will do the same.            </p> 
            <br />
          </div>
        </div>

        <button className="expandable-btn" onClick={() => setHiddenTips(!hiddenTips)}>
          <div>
            <p className="slide-down-label">Tips for Travelers</p>
            <IoIosArrowDown
              className={hiddenTips ? "arrow-down-closed" : "arrow-down-open"}
            />
          </div>
        </button>
        <div className={hiddenTips ? "slide-up" : "slide-down"}>
          <div className="slide-down-content">
            <p>
              We have made a map compiling our favourite things to do in the city <a href="https://www.google.com/maps/d/viewer?mid=1aacMcW3oIw2WdObmwRk2bsJwv9U0pe8&ll=59.317962811642396%2C18.048950408213898&z=13" target="_blank">here!</a>
            </p>
            <br />
            <p>
              Traveling in Stockholm with public transportation is super easy! Download the <a href="https://sl.se/en/in-english" target="_blank">SL app</a> and get yourself a ticket suitable for your stay.
            </p>
            <br />
            <p>
              The website <a href="https://www.visitstockholm.com/" target="_blank">VisitStockholm</a> offers a lot of fun touristy tips for anyone new to the city.
            </p>
            <br />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Info;