import { IoIosArrowRoundForward } from 'react-icons/io/index';

interface IPlaceToStay {
  name: string;
  link: string;
  isActive: boolean;
}

const placesToStay: IPlaceToStay[] = [
  { name: 'Rygerfjord', link: 'https://rygerfjord.se/', isActive: false },
  {
    name: 'Den Röda Båten',
    link: 'https://www.booking.com/hotel/se/den-rapda-bay-ten.sv.html',
    isActive: false,
  },
  {
    name: 'Reimersholme hotel',
    link: 'https://reimersholmehotel.se/',
    isActive: false,
  },
];

const StayOtherPlaces = () => {
  return (
    <div className="stay-other-places">
      <div className="imgContainer">
        <img
          src="assets/images/hotel.jpg"
          alt="Långholmen Hotell"
        ></img>
      </div>
      <h1>Other places to stay</h1>
      <p>
        Still want to be close to us, just not at the Långholmen hotel? Here are
        some other great hotels that are close by the venue.
      </p>
      <div className="places-to-stay-wrapper">
        {placesToStay.map((place) => {
          return (
            <a
              className="place-to-stay-link"
              href={place.link}
              target="_blank"
              rel="noreferrer"
              key={place.name}
            >
              <div className="place-name-item">
                <p className="place-name-label">{place.name}</p>
                <IoIosArrowRoundForward className="place-name-arrow" />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default StayOtherPlaces;
