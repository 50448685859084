import React, { ChangeEvent, useState, useEffect } from 'react';

function RSVPForm() {
  const [status, setStatus] = useState('Not submitted');
  const [guestInfo, setGuestInfo] = useState({
    status: '',
    name: '',
    allergies: '',
    music: '',
  });

  function addGuestStatus(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const value = e.currentTarget.id;
    setGuestInfo({ ...guestInfo, status: value });
  }

  function addGuestName(e: ChangeEvent<HTMLInputElement>) {
    const nameValue = e.target.value;
    setGuestInfo({ ...guestInfo, name: nameValue });
  }
  function addGuestAllergies(e: ChangeEvent<HTMLInputElement>) {
    const nameValue = e.target.value;
    setGuestInfo({ ...guestInfo, allergies: nameValue });
  }
  function addGuestMusic(e: ChangeEvent<HTMLInputElement>) {
    const nameValue = e.target.value;
    setGuestInfo({ ...guestInfo, music: nameValue });
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (guestInfo.status === '') {
      alert('Plese select if you want to accept or decline the invitation.');
      return;
    }

    setStatus('Submitting...');

    //Change this when we deploy server
    let response = await fetch('https://pej-oskar-wedding.fly.dev/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(guestInfo),
    });

    let result = await response.json();
    setStatus(result.status);
    console.log('status', result.status);
  };

  useEffect(() => {
    const acceptBtn = document.getElementById('accept');
    const declineBtn = document.getElementById('decline')
    if (guestInfo.status === '') {
      acceptBtn?.classList.remove('accept-active');
      declineBtn?.classList.remove('decline-active');
    } else if (guestInfo.status === 'accept') {
      acceptBtn?.classList.add('accept-active');
      declineBtn?.classList.remove('decline-active');
    } else if (guestInfo.status === 'decline') {
      acceptBtn?.classList.remove('accept-active');
      declineBtn?.classList.add('decline-active');
    }
  }, [guestInfo.status]);

  let rsvpResponse = '';

  if (guestInfo.status === 'accept') {
    rsvpResponse = "See you in August, yay!";
  } else if (guestInfo.status === 'decline') {
    rsvpResponse = "Thanks for letting us know!";
  }

  return (
    <>
      <a
        className="anchor"
        id="anchor-rsvp"
      ></a>
      <div id="rsvp-container">
        <h1 id="rsvp-header">RSVP</h1>
        {status === 'Not submitted' &&
            <form onSubmit={handleSubmit}>
            <label id="rsvp-form">Your Name(s)</label>
            <input
              className="rsvp-input"
              type="text"
              placeholder="Enter your name"
              id="name"
              onChange={(e) => {
                addGuestName(e);
              }}
              required
            />
            <div id="button-container">
              <button
                className="rsvp-button"
                id="accept"
                onClick={(e) => {
                  addGuestStatus(e);
                }}
              >
                Joyfully accepts
              </button>
              <button
                className="rsvp-button"
                id="decline"
                onClick={(e) => {
                  addGuestStatus(e);
                }}
              >
                Regretfully declines
              </button>
            </div>
            <label>
              Dietary restrictions <span className="text">(Optional)</span>
            </label>
            <input
              className="rsvp-input"
              type="text"
              placeholder="List any allergies or restrictions"
              id="allergies"
              onChange={(e) => {
                addGuestAllergies(e);
              }}
            />
            <label>
              A song that makes you dance <span className="text">(Optional)</span>
            </label>
            <input
              className="rsvp-input"
              type="text"
              placeholder="What makes you lose it on the dancefloor?"
              id="music"
              onChange={(e) => {
                addGuestMusic(e);
              }}
            />
            <input
              id="rsvp-submit"
              type="submit"
              value="Submit"
            />
          </form>
        }
        {status === "Message Sent" &&
          <div className="rsvp-response">
            <p>{rsvpResponse}</p>
          </div>
        }
        {status === "ERROR" &&
          <div className="rsvp-response">
            <p>There was an error submitting your response. Please try again, or reach out to us directly if the problem continues.</p>
          </div>
        }
      </div>
    </>
  );
}

export default RSVPForm;