import HeroImage from "../HeroImage/HeroImage";

const Splash = () => {
  return (
    <div className="splash-container">
      <HeroImage type={"invite"} />
      <p>So technically we are officially marrying in May 2023. We want to celebrate this special event with all our friends and family in August. If you have access to this website you are invited and we would love for you to come dance the night away on beautiful Södermalm, Stockholm!</p>
      <br />
      <p>Please let us know if you are able to come before first of July by using the RSVP form at the bottom of the website.</p>

    </div>
  );
};

export default Splash;