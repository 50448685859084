import React from "react";
import StayOtherPlaces from "./StayOtherPlaces";
import StayWithUs from "./StayWithUs";

const Stay = () => {
  return (
    <div>
      <StayWithUs />
      <StayOtherPlaces />
    </div>
  );
};

export default Stay;
